// Generic imports
import React from "react"
import styled from "styled-components"

// Other components imports

// Redux imports

// Static imports
import alertErrorSvg from "images/order-class/alert-error.svg"

// Utils imports

const Styles = styled.div`
  padding: 10px;
  background: rgba(84, 178, 229, 0.1);
  border-radius: 4px;
  display: flex;
  align-items: center;
`

const AlertIcon = styled.div`
  width: 44px;
  padding-right: 5px;

  img {
    width: 26px;
  }
`

const AlertText = styled.div`
  color: #3d81a6;
`

export default function Alert({ children, ...props }) {
  return (
    <Styles className="taman-alert" {...props}>
      <AlertIcon>
        <img src={alertErrorSvg} alt="alert error" />
      </AlertIcon>
      <AlertText>{children}</AlertText>
    </Styles>
  )
}
