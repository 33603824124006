/* eslint-disable camelcase */
// Generic imports
import React, { useState, useEffect } from "react"
import { navigate } from "gatsby"
import { useFormik } from "formik"
import { toast } from "react-toastify"
import { connect } from "react-redux"
import "react-input-range/lib/css/index.css"

import Button from "../Button"
import Input from "../Reusable/Input"
import SelectSquare from "../Reusable/Input/select-square"
// import RadioList from "../Reusable/Input/radio-list"
import Checkbox from "../Reusable/Input/checkbox"
import ClassTypeWrapper from "components/Reusable/ClassTypeWrapper"
import InputJadwal from "./input-jadwal"
import PaketPembayaran from "./PaketPembayaran"
import UndangTeman from "./UndangTeman"
import Alert from "../Reusable/Alert"
import { translateDay } from "./JadwalComponent"
// import Image from "../image"
// import InputRange from "../Reusable/Input/input-range"
import { Styles } from "components/OrderClass/style"

// Redux imports
import * as sessionSelectors from "rdx/slices/session/selectors"

// Static imports
import verifiedLogo from "images/verified.png"

// Utils imports
import { dummyKelas } from "data/dummy/dummykelas"
import { getDiskonPerPaket } from "services/kelas"
import { formatMoney } from "services/utils"
import { orderClass } from "services/order"

const isSeikhlasnya = type => type === "TS" || type === "AS"

export const sortJadwal = jadwalGroups => {
  jadwalGroups?.forEach(jadwalGroup => {
    jadwalGroup?.jadwals.sort((a, b) => {
      const aPos = (Number(a.day) + 5) % 7
      const bPos = (Number(b.day) + 5) % 7
      if (aPos !== bPos) {
        return aPos - bPos
      }
      if (a.hour_start < b.hour_start) return -1
      if (b.hour_start < a.hour_start) return 1
      return 0
    })
  })
}

const getFilteredJadwalGroups = (
  kelas,
  paketPembayaranJadwalLogic,
  elemPaketPembayaran
) => {
  let jadwalGroups = kelas?.jadwal_groups
  if (jadwalGroups && paketPembayaranJadwalLogic && elemPaketPembayaran) {
    const allowedJadwals = paketPembayaranJadwalLogic[elemPaketPembayaran.judul]
    jadwalGroups = jadwalGroups.filter(jadwalGroup => {
      return allowedJadwals.includes(jadwalGroup.pk)
    })
  }
  return jadwalGroups
}

const mapStateToProps = state => ({
  user: sessionSelectors.getUser(state),
})

const mapDispatchToProps = {}

const withConnect = connect(mapStateToProps, mapDispatchToProps)

const OrderClass = props => {
  const { user } = props
  const [kelas, setKelas] = useState(dummyKelas)
  const [loading, setLoading] = useState(false)
  const [listTeman, setListTeman] = useState([])
  const [diskon, setDiskon] = useState([])
  const [additionalInpData, setAdditionalInpData] = useState(null)

  const formik = useFormik({
    initialValues: {
      materi: null,
      data_siswa_checkbox: 0,
      nama_siswa: "",
      nomor_hp: "",
      data_orang_tua_checkbox: 0,
      nama_orang_tua: "",
      nomor_hp_orang_tua: "",
      email_orang_tua: "",
      jadwal_pilihan_pertama: null,
      jadwal_pilihan_kedua: null,
      paket_pembayaran: 0,
      list_teman: [],
      paket_pertemuan: 0,
      total_price: 0,
      agree_additional_checkbox: 0,
    },
    onSubmit: async values => {
      try {
        if (!isSeikhlasnya(kelas.type)) {
          if (!values.paket_pembayaran) {
            toast.error("Anda harus mengisi paket pembayaran")
            return
          }
          if (!values.paket_pertemuan) {
            toast.error("Anda harus mengisi paket pertemuan")
            return
          }
        }
        if (
          kelas.additional_checkbox &&
          values.agree_additional_checkbox !== 1
        ) {
          toast.error("Anda harus menyetujui persyaratan kelas")
          return
        }
        setLoading(true)
        const paketPembayaranFinal = kelas.paket_pembayaran?.filter(x => {
          return x.id === formik.values.paket_pembayaran
        })[0]
        if (
          !isSeikhlasnya(kelas.type) &&
          listTeman.length + 1 !== paketPembayaranFinal.banyak_pendaftar
        ) {
          toast.error("Banyak teman yang diundang tidak sesuai")
        } else {
          if (additionalInpData) {
            values.additional_input = JSON.stringify(additionalInpData)
          }
          const resp = await orderClass(values)
          toast.success("Berhasil mendaftar kelas!")
          if (resp.data.token === "TC0") {
            navigate(`/payment/finish/`)
          } else {
            navigate(`/payment?token=${resp.data.token}`)
          }
        }
      } catch {
        toast.error("Gagal mendaftar kelas. Coba beberapa saat lagi")
      } finally {
        setLoading(false)
      }
    },
  })

  useEffect(() => {
    if (user?.email) {
      setTimeout(() => {
        formik.setValues(formikVal => {
          return {
            ...formikVal,
            data_siswa_checkbox: 1,
            nama_siswa: `${user.first_name} ${user.last_name}`.trim(),
            nomor_hp: user.phone,
          }
        })
      }, 500)
    }
  }, [user])

  useEffect(() => {
    if (props.kelas?.slug) {
      getDiskonPerPaket(props.kelas.slug).then(resp => {
        if (resp?.data) {
          setDiskon(resp.data)
        }
      })
      const newKelas = JSON.parse(JSON.stringify(props.kelas))
      newKelas.paket_pembayaran = newKelas.paket_pembayaran.filter(x => {
        return x.active
      })
      newKelas.paket_pembayaran.sort((a, b) => {
        return a.id < b.id
      })
      newKelas.paket_pertemuan = newKelas.paket_pertemuan.filter(x => {
        return x.active
      })
      newKelas.paket_pertemuan.sort((a, b) => {
        return a.banyak_pertemuan - b.banyak_pertemuan
      })
      newKelas.materi = newKelas.materi?.filter(x => {
        return x.active
      })
      sortJadwal(newKelas.jadwal_groups)
      setTimeout(() => {
        const newValues = {
          paket_pertemuan: newKelas.paket_pertemuan[0].id,
          paket_pembayaran: newKelas.paket_pembayaran[0].id,
          jadwal_pilihan_pertama:
            newKelas.jadwal_groups?.length > 0
              ? newKelas.jadwal_groups[0].pk
              : null,
          jadwal_pilihan_kedua:
            newKelas.jadwal_groups?.length > 0
              ? newKelas.jadwal_groups[0].pk
              : null,
        }
        if (newKelas.materi?.length > 0) {
          newValues.materi = newKelas.materi[0].id
        }
        formik.setValues(formikVal => {
          return {
            ...formikVal,
            ...newValues,
          }
        })
        // Custom Additional Input default value initializations
        if (newKelas.additional_paket_logic) {
          const additionalLogic = JSON.parse(newKelas.additional_paket_logic)
          if (additionalLogic?.additional_input) {
            let needUpdate = false
            const initDefaultAdditional = {}
            additionalLogic.additional_input.forEach(oneInput => {
              if (oneInput.default) {
                needUpdate = true
                initDefaultAdditional[oneInput.name] = oneInput.default
              }
            })
            if (needUpdate) {
              setAdditionalInpData(old => {
                const temp = old ? { ...old } : {}
                return {
                  ...temp,
                  ...initDefaultAdditional,
                }
              })
            }
          }
        }
        setKelas(newKelas)
      }, 1000)
    }
  }, [props.kelas])

  const elemPaketPembayaran = kelas.paket_pembayaran?.filter(x => {
    return x.id === formik.values.paket_pembayaran
  })[0]

  const elemPaketPertemuan = kelas.paket_pertemuan?.filter(x => {
    return x.id === formik.values.paket_pertemuan
  })[0]

  const diskonDidapat = diskon.filter(val => {
    return (
      val.paket_pembayaran.id === formik.values.paket_pembayaran &&
      val.paket_pertemuan.id === formik.values.paket_pertemuan
    )
  })

  let totalHarga = 0
  let totalDiskon = 0
  if (kelas) {
    if (isSeikhlasnya(kelas.type)) {
      totalHarga = formik.values.total_price
    } else if (elemPaketPembayaran && elemPaketPertemuan) {
      totalHarga =
        elemPaketPembayaran.banyak_pendaftar *
        elemPaketPembayaran.harga_per_orang_sesi *
        elemPaketPertemuan.banyak_pertemuan
      if (diskonDidapat?.length > 0) {
        totalDiskon = Math.round(totalHarga * diskonDidapat[0].potongan)
        totalHarga = totalHarga - totalDiskon
      }
    }
  }

  let additionalLogic = {}
  let paketPembayaranJadwalLogic = null
  let additionalInput = []
  if (kelas?.additional_paket_logic) {
    additionalLogic = JSON.parse(kelas.additional_paket_logic)
    if (additionalLogic?.paket_pembayaran_jadwal_group) {
      paketPembayaranJadwalLogic = additionalLogic.paket_pembayaran_jadwal_group
    }
    if (additionalLogic?.additional_input) {
      additionalInput = additionalLogic.additional_input
    }
  }

  const jadwalGroups = getFilteredJadwalGroups(
    kelas,
    paketPembayaranJadwalLogic,
    elemPaketPembayaran
  )

  const listMateri = kelas?.materi

  const elemMateri = kelas.materi?.filter(x => {
    return x.id === formik.values.materi
  })[0]

  const elemJadwalPilihanPertama = jadwalGroups?.filter(x => {
    return x.pk === formik.values.jadwal_pilihan_pertama
  })[0]

  const elemJadwalPilihanKedua = jadwalGroups?.filter(x => {
    return x.pk === formik.values.jadwal_pilihan_kedua
  })[0]

  return (
    <Styles>
      <form onSubmit={formik.handleSubmit}>
        <div className="class-description">
          <div className="layout">
            <div className="class-logo-and-desc">
              <div>
                <div
                  className="class-logo"
                  style={
                    kelas.logo
                      ? {
                          backgroundImage: `url(${kelas.logo})`,
                          borderRadius: "16px",
                        }
                      : { background: "white", borderRadius: "16px" }
                  }
                ></div>
              </div>
              <div>
                <div className="class-type-wrapper">
                  <ClassTypeWrapper type={kelas.type} />
                </div>
                <h2>
                  {kelas.name}{" "}
                  {kelas.is_verified && (
                    <img
                      className="is-verified"
                      src={verifiedLogo}
                      alt="verified"
                    />
                  )}
                </h2>
                <h6 style={{ color: "#B0B0B0" }}>
                  by{" "}
                  <span>
                    {kelas.owner.first_name + " " + kelas.owner.last_name}
                  </span>
                </h6>
                <div style={{ marginTop: "1rem" }}>{kelas.description}</div>
              </div>
            </div>
          </div>
        </div>
        <div className="class-order-form">
          <div className="layout-2">
            <div className="section-white">
              <h2>DATA DIRI</h2>
              <div className="data_diri_wrapper">
                <Checkbox
                  id="id-data_siswa_checkbox"
                  name="data_siswa_checkbox"
                  value={formik.values.data_siswa_checkbox}
                  onChange={val => {
                    if (val === 1) {
                      formik.setValues({
                        ...formik.values,
                        data_siswa_checkbox: val,
                        nama_siswa: `${user.first_name} ${user.last_name}`.trim(),
                        nomor_hp: user.phone,
                      })
                    } else {
                      formik.setValues({
                        ...formik.values,
                        data_siswa_checkbox: val,
                      })
                    }
                  }}
                >
                  Data diri sama dengan data profil
                </Checkbox>
                <Input
                  id="id-class-nama_siswa"
                  name="nama_siswa"
                  type="text"
                  placeholder="Tulis jawaban disini"
                  label="Nama Siswa"
                  value={formik.values.nama_siswa}
                  onChange={formik.handleChange}
                  maxLength={200}
                  required
                />
                <Input
                  id="id-class-nomor_hp"
                  name="nomor_hp"
                  type="text"
                  placeholder="Tulis jawaban disini"
                  label="Nomor HP"
                  value={formik.values.nomor_hp}
                  onChange={formik.handleChange}
                  maxLength={200}
                  required
                />
                <br />
                <Checkbox
                  id="id-data_orang_tua_checkbox"
                  name="data_orang_tua_checkbox"
                  value={formik.values.data_orang_tua_checkbox}
                  onChange={val => {
                    formik.setValues({
                      ...formik.values,
                      data_orang_tua_checkbox: val,
                    })
                  }}
                >
                  Sertakan data orang tua
                </Checkbox>
                {formik.values.data_orang_tua_checkbox ? (
                  <>
                    <Input
                      id="id-class-nama_orang_tua"
                      name="nama_orang_tua"
                      type="text"
                      placeholder="Tulis jawaban disini"
                      label="Nama Orang Tua"
                      value={formik.values.nama_orang_tua}
                      onChange={formik.handleChange}
                      maxLength={200}
                      required
                    />
                    <Input
                      id="id-class-nomor_hp_orang_tua"
                      name="nomor_hp_orang_tua"
                      type="text"
                      placeholder="Tulis jawaban disini"
                      label="No. HP Orang Tua"
                      value={formik.values.nomor_hp_orang_tua}
                      onChange={formik.handleChange}
                      maxLength={200}
                      required
                    />
                    <Input
                      id="id-class-email_orang_tua"
                      name="email_orang_tua"
                      type="text"
                      placeholder="Tulis jawaban disini"
                      label="Email Orang Tua"
                      value={formik.values.email_orang_tua}
                      onChange={formik.handleChange}
                      maxLength={200}
                      required
                    />
                  </>
                ) : (
                  <></>
                )}
              </div>
            </div>
            <br />
            <br />
            {jadwalGroups?.length > 0 || listMateri?.length > 0 ? (
              <>
                <div className="section-white">
                  <h2>MATERI & JADWAL</h2>
                  {listMateri?.length > 0 ? (
                    <div className="section-materi">
                      <div>Materi</div>
                      <SelectSquare
                        id="id-materi"
                        name="materi"
                        defaultValue={formik.values.materi}
                        onChange={value => {
                          formik.setValues({
                            ...formik.values,
                            materi: value,
                          })
                        }}
                        list={
                          listMateri
                            ? listMateri.map(({ id, nama }) => {
                                return {
                                  text: nama,
                                  value: id,
                                }
                              })
                            : []
                        }
                      />
                    </div>
                  ) : (
                    <></>
                  )}
                  {jadwalGroups?.length > 0 ? (
                    <>
                      <br />
                      <div>Jadwal Pilihan Pertama</div>
                      <div className="list-jadwal-kelas">
                        <InputJadwal
                          jadwalGroups={jadwalGroups}
                          clickedJadwal={newValue => {
                            formik.setValues({
                              ...formik.values,
                              jadwal_pilihan_pertama: newValue,
                            })
                          }}
                          value={formik.values.jadwal_pilihan_pertama}
                        />
                      </div>
                      <div>Jadwal Pilihan Kedua</div>
                      <div className="list-jadwal-kelas">
                        <InputJadwal
                          jadwalGroups={jadwalGroups}
                          clickedJadwal={newValue => {
                            formik.setValues({
                              ...formik.values,
                              jadwal_pilihan_kedua: newValue,
                            })
                          }}
                          value={formik.values.jadwal_pilihan_kedua}
                        />
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
                <br />
                <br />
              </>
            ) : (
              <></>
            )}
            {isSeikhlasnya(kelas.type) ? (
              <></>
            ) : (
              <div className="section-white">
                <h2>PAKET PEMBAYARAN & PERTEMUAN</h2>
                {diskon?.length > 0 ? (
                  <>
                    <Alert>
                      {diskon.map((val, index) => (
                        <div key={index}>
                          - Anda akan mendapatkan diskon sebesar{" "}
                          {Math.round(val.potongan * 100)}% jika memilih{" "}
                          {val.paket_pembayaran.judul} dengan{" "}
                          {val.paket_pertemuan.banyak_pertemuan} PERTEMUAN
                        </div>
                      ))}
                    </Alert>
                    <br />
                  </>
                ) : (
                  <></>
                )}
                <div style={{ paddingTop: ".25rem", paddingBottom: ".25rem" }}>
                  Paket Pembayaran
                </div>
                <div className="list-paket-pembayaran">
                  {kelas.paket_pembayaran?.map(
                    (
                      {
                        id,
                        judul,
                        banyak_pendaftar,
                        detail_banyak_pendaftar,
                        harga_per_orang_sesi,
                      },
                      paketId
                    ) => (
                      <PaketPembayaran
                        key={id}
                        judul={judul}
                        siswa={banyak_pendaftar}
                        harga={harga_per_orang_sesi}
                        detail1={detail_banyak_pendaftar}
                        detail2="per orang per sesi"
                        onClick={() => {
                          const jadwalGroups = getFilteredJadwalGroups(
                            kelas,
                            paketPembayaranJadwalLogic,
                            kelas.paket_pembayaran[paketId]
                          )
                          formik.setValues({
                            ...formik.values,
                            paket_pembayaran: id,
                            jadwal_pilihan_pertama:
                              jadwalGroups?.length > 0
                                ? jadwalGroups[0].pk
                                : null,
                            jadwal_pilihan_kedua:
                              jadwalGroups?.length > 0
                                ? jadwalGroups[0].pk
                                : null,
                          })
                        }}
                        className={
                          formik.values.paket_pembayaran === id ? "active" : ""
                        }
                      />
                    )
                  )}
                </div>
                <div style={{ paddingTop: "1rem", paddingBottom: ".25rem" }}>
                  Pertemuan
                </div>
                <div className="list-paket-pertemuan">
                  <SelectSquare
                    id="id-paket-pertemuan"
                    name="paket-pertemuan"
                    defaultValue={formik.values.paket_pertemuan}
                    onChange={value => {
                      formik.setValues({
                        ...formik.values,
                        paket_pertemuan: value,
                      })
                    }}
                    list={
                      kelas.paket_pertemuan
                        ? kelas.paket_pertemuan.map(
                            ({ id, banyak_pertemuan }) => {
                              return {
                                text: `${banyak_pertemuan} PERTEMUAN`,
                                value: id,
                              }
                            }
                          )
                        : []
                    }
                  />
                </div>
                <br />
                <div className="list-additional-input">
                  {additionalInput.map((oneInput, index) => {
                    if (oneInput.type === "option") {
                      return (
                        <div key={index}>
                          <div style={{ paddingBottom: ".25rem" }}>
                            {oneInput.label}
                          </div>
                          <SelectSquare
                            id={`id-${oneInput.name}`}
                            name={oneInput.name}
                            defaultValue={oneInput.default}
                            onChange={value => {
                              setAdditionalInpData(old => {
                                const temp = old ? { ...old } : {}
                                temp[oneInput.name] = value
                                return temp
                              })
                            }}
                            list={oneInput.choices}
                          />
                        </div>
                      )
                    }
                    return <></>
                  })}
                </div>
                <br />
                <div>
                  Undang teman untuk bersenang-senang bersamamu di kelas ini
                </div>
                <div>
                  {listTeman.map((teman, index) => (
                    <div key={index}>
                      -{" "}
                      <span style={{ color: "#51b848" }}>
                        {teman.first_name + " " + teman.last_name + " "}(
                        {teman.uname_email})
                      </span>
                      <span
                        className="cancel-teman"
                        onClick={() => {
                          setListTeman([
                            ...listTeman.slice(0, index),
                            ...listTeman.slice(index + 1),
                          ])
                          formik.setValues({
                            ...formik.values,
                            list_teman: [
                              ...formik.values.list_teman.slice(0, index),
                              ...formik.values.list_teman.slice(index + 1),
                            ],
                          })
                        }}
                      >
                        x
                      </span>
                    </div>
                  ))}
                </div>
                <div style={{ paddingTop: ".5rem", paddingBottom: "1rem" }}>
                  <UndangTeman
                    name="undang-teman"
                    placeholder="Cari Username atau Email"
                    addTeman={val => {
                      setListTeman([...listTeman, ...val])
                      formik.setValues({
                        ...formik.values,
                        list_teman: [
                          ...formik.values.list_teman,
                          ...val.map(x => x.pk),
                        ],
                      })
                    }}
                  />
                </div>
                <Alert>
                  Undangan kepada teman akan dikonfirmasi. Tunggu notifikasi
                  berikutnya
                </Alert>
              </div>
            )}
            <br />
            <br />
            <div className="section-white">
              <h2>RINCIAN PEMESANAN</h2>
              {isSeikhlasnya(kelas.type) ? (
                <div>
                  <div>Jumlah Donasi (dalam Rupiah)</div>
                  <Input
                    id="id_total_price"
                    name="total_price"
                    type="number"
                    placeholder="10000"
                    value={formik.values.total_price}
                    onChange={formik.handleChange}
                    required
                    min={1}
                  />
                  <br />
                  <br />
                  <br />
                </div>
              ) : (
                <>
                  {kelas?.catatan_pembelian && (
                    <div
                      style={{
                        whiteSpace: "pre",
                        paddingTop: ".5rem",
                      }}
                    >
                      <div
                        style={{
                          paddingBottom: ".1rem",
                        }}
                      >
                        Catatan dari Penyedia Kelas
                      </div>
                      {kelas.catatan_pembelian}
                    </div>
                  )}
                  {/* <Checkbox
                    id="id-bayar-cicilan"
                    name="bayar-cicilan"
                  >Bayar dengan cicilan</Checkbox> */}
                </>
              )}
              <div className="list-biaya">
                <div className="list-biaya-1">
                  {!isSeikhlasnya(kelas.type) ? (
                    <>
                      <div className="bold-title">Kelas</div>
                      <div>{kelas.name}</div>
                      <br />
                    </>
                  ) : (
                    <></>
                  )}
                  {elemMateri ? (
                    <>
                      <div className="bold-title">Materi</div>
                      <div>{elemMateri.nama}</div>
                      <br />
                    </>
                  ) : (
                    <></>
                  )}
                  {elemJadwalPilihanPertama && elemJadwalPilihanKedua ? (
                    <>
                      <div className="bold-title">Jadwal</div>
                      <ol
                        style={{
                          paddingLeft: "20px",
                        }}
                      >
                        <li>
                          {elemJadwalPilihanPertama.jadwals.map(jadwal => (
                            <div key={jadwal.id}>
                              {translateDay[jadwal.day]},{" "}
                              {jadwal.hour_start.slice(0, 5) +
                                " - " +
                                jadwal.hour_end.slice(0, 5)}
                            </div>
                          ))}
                        </li>
                        <li>
                          {elemJadwalPilihanKedua.jadwals.map(jadwal => (
                            <div key={jadwal.id}>
                              {translateDay[jadwal.day]},{" "}
                              {jadwal.hour_start.slice(0, 5) +
                                " - " +
                                jadwal.hour_end.slice(0, 5)}
                            </div>
                          ))}
                        </li>
                      </ol>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
                <div className="list-biaya-3">
                  {isSeikhlasnya(kelas.type) ? (
                    <>
                      <div className="bold-title">Kelas</div>
                      <div>{kelas.name}</div>
                    </>
                  ) : (
                    <></>
                  )}
                  {!isSeikhlasnya(kelas.type) &&
                  elemPaketPembayaran &&
                  elemPaketPertemuan ? (
                    <>
                      <div className="bold-title">Paket Pembayaran</div>
                      <div className="left-right-div">
                        <div>
                          {elemPaketPembayaran.judul}: Rp
                          {formatMoney(
                            elemPaketPembayaran.harga_per_orang_sesi
                          )}{" "}
                          x {elemPaketPertemuan.banyak_pertemuan}
                        </div>
                        <div>
                          Rp
                          {formatMoney(
                            elemPaketPembayaran.harga_per_orang_sesi *
                              elemPaketPertemuan.banyak_pertemuan
                          )}
                        </div>
                      </div>
                      <br />
                      <div className="bold-title">Pertemuan</div>
                      <div>{elemPaketPertemuan.banyak_pertemuan} Pertemuan</div>
                      {totalDiskon ? (
                        <>
                          <br />
                          <div className="left-right-div">
                            <div>
                              Potongan {elemPaketPertemuan.banyak_pertemuan}{" "}
                              Pertemuan
                            </div>
                            <div>-Rp{formatMoney(totalDiskon)}</div>
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                    </>
                  ) : (
                    <></>
                  )}
                  <hr
                    style={{
                      borderWidth: "2px",
                    }}
                  />
                  <div className="total-harga left-right-div">
                    <div>TOTAL</div>
                    <div>Rp{formatMoney(totalHarga)}</div>
                  </div>
                </div>
              </div>
              {kelas.additional_checkbox ? (
                <div style={{ paddingTop: "10px" }}>
                  <Checkbox
                    name="agree_additional_checkbox"
                    onChange={val => {
                      formik.setValues({
                        ...formik.values,
                        agree_additional_checkbox: val,
                      })
                    }}
                  >
                    <span
                      dangerouslySetInnerHTML={{
                        __html: kelas.additional_checkbox,
                      }}
                    />
                  </Checkbox>
                </div>
              ) : (
                <></>
              )}
            </div>
            <br />
            <div className="btn-checkout">
              <Button disabled={loading}>
                {loading ? "Loading..." : "CHECKOUT"}
              </Button>
            </div>
          </div>
        </div>
      </form>
    </Styles>
  )
}

export default withConnect(OrderClass)
