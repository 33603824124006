// Generic imports
import React, { useState, useEffect } from "react"
import styled from "styled-components"

const Styles = styled.div`
  display: inline-block;
  width: 100%;

  label {
    color: #51b848;
  }

  .select-square-list > * {
    display: inline-block;
    background: white;
    border: 1px solid #e8e8e8;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 12px 18px;
    margin: 5px;
    cursor: pointer;
  }

  .select-square-list > *.active {
    outline: 2px solid #51b848;
  }
`

export default function SelectSquare(props) {
  const { label, id, name, list, onChange, defaultValue } = props
  const inputId = id !== null ? id : name

  const [inpValue, setInpValue] = useState("")

  useEffect(() => {
    if (defaultValue) {
      setInpValue(defaultValue)
    }
  }, [defaultValue])

  return (
    <Styles className="taman-select-square">
      {label ? <label htmlFor={inputId}>{label}</label> : <></>}
      <input name={name} type="hidden" value={inpValue} />
      <div className="select-square-list">
        {list.map(({ text, value }, index) => (
          <div
            key={index}
            onClick={() => {
              setInpValue(value)
              if (onChange) onChange(value)
            }}
            className={inpValue === value ? "active" : ""}
          >
            {text}
          </div>
        ))}
      </div>
    </Styles>
  )
}
