const currentDate = new Date().toISOString().split("T")[0]

export const dummyKelas = {
  id: -1,
  tags: [],
  jadwal_groups: [],
  tutees: [],
  owner: {
    first_name: "",
    last_name: "",
    photo: "",
  },
  city: {
    id: 1,
    code: 0,
    name: "Depok",
    provinsi: {
      name: "Jawa Barat",
    },
  },
  slug: "",
  name: "",
  is_verified: false,
  type: "TC",
  logo: "",
  poster: "",
  rating: null,
  category: null,
  platform: null,
  contact_name: "",
  contact_phone: "",
  description: "",
  objective: "",
  overview: "",
  program: "",
  syllabus: "",
  tamanscout: "",
  syllabus_link: null,
  registration_due: currentDate,
  timeline_start: currentDate,
  timeline_end: currentDate,
}
