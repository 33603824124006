import React from "react"
import styled from "styled-components"

import { formatMoney } from "services/utils"

const Styles = styled.div`
  display: inline-block;
  text-align: center;
  border: 1px solid #e8e8e8;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  color: #a0a4a8;
  max-width: 300px;
  width: 100%;
  padding-top: 20px;
  padding-bottom: 26px;
  margin: 15px;

  .jadwal-judul {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding-bottom: 16px;
  }

  .jadwal-detail1 {
    padding-top: 16px;
    padding-bottom: 4px;
  }

  .jadwal-harga {
    font-family: "Source Serif Pro", serif;

    span {
      display: inline-block;
      position: relative;
      bottom: 24px;
    }

    h1 {
      font-family: "Source Serif Pro", serif;
      display: inline-block;
      font-size: 3rem;
      margin-bottom: 0.25rem;
    }
  }

  &.active {
    color: black;
    outline: 2px solid #51b848;

    .jadwal-harga h1 {
      color: #51b848;
    }
  }
`

const shortenHarga = harga => {
  while (harga >= 1000.0) harga /= 1000.0
  return harga
}

const getHargaEnding = harga => {
  if (harga >= 1000000000.0) return "M"
  else if (harga >= 1000000.0) return "jt"
  else if (harga >= 1000.0) return "k"
  else return ""
}

export default function PaketPembayaran({
  judul,
  siswa,
  detail1,
  detail2,
  harga,
  ...props
}) {
  return (
    <Styles {...props}>
      <div className="jadwal-judul">{judul}</div>
      <div className="jadwal-detail1">{detail1}</div>
      <div className="jadwal-harga">
        <span>Rp</span>
        {harga % 100 === 0 ? (
          <h1>
            {shortenHarga(harga)}
            {getHargaEnding(harga)}
          </h1>
        ) : (
          <h1>{formatMoney(harga)}</h1>
        )}
      </div>
      <small>{detail2}</small>
    </Styles>
  )
}
