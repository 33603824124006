// Generic imports
import styled from "styled-components"

// Static imports

export const Styles = styled.div`
  background: #fafafa;
  font-family: "Avenir", Helvetica, sans-serif;

  h1,
  h2,
  h3,
  h4,
  h5 {
    font-family: "Avenir", Helvetica, sans-serif;
    font-weight: 900;
  }

  .layout {
    max-width: 1100px;
    margin: auto;
    padding-left: 20px;
    padding-right: 20px;
  }

  .layout-2 {
    max-width: 1300px;
    margin: auto;
    padding-left: 10px;
    padding-right: 10px;
  }

  .class-description {
    padding-top: 40px;
    padding-bottom: 40px;
    background: #ffffff;
  }

  .class-order-form {
    padding-top: 40px;
    padding-bottom: 40px;
    background: #f7f7f7;

    h2 {
      color: #818181;
      font-weight: 500;
      font-size: 1.85rem;
      padding-bottom: calc(10px + 2%);
    }

    .section-white {
      background: #ffffff;
      padding: 4% 5%;
    }
  }

  .class-logo-and-desc {
    display: flex;
  }

  .class-logo-and-desc > div:nth-child(1) {
    width: 300px;
  }

  .class-logo-and-desc > div:nth-child(2) {
    width: calc(100% - 300px);
  }

  .class-logo {
    width: 260px;
    margin-right: 40px;
    border: 1px solid #cacccf;
    box-sizing: border-box;
    border-radius: 4px;
    height: 260px;
    background-repeat: no-repeat;
    background-position: center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .class-photo-wrapper {
    margin: auto;
    margin-top: 3rem;
    margin-bottom: 2rem;
    max-width: 150px;
  }

  .class-photo {
    margin: auto;
    border-radius: 50%;
    filter: "drop-shadow(0px 0px 30px rgba(160, 164, 168, 0.25))";
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    padding-bottom: 100%;
    background-position: center center;
  }

  .is-verified {
    width: 15px;
    position: relative;
    left: 2px;
    bottom: 8px;
  }

  .class-type-wrapper {
    padding-bottom: 20px;
  }

  .taman-input,
  .taman-select {
    padding-top: 0.5rem;
  }

  label {
    color: black;
  }

  .btn-checkout {
    text-align: center;
  }

  .pemahaman-container {
    background-color: #ffffff;
    border-radius: 8px;
    width: 100%;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 20px;
    margin-bottom: 20px;

    .pemahaman-desc {
      font-size: 20px;
      padding-left: 8px;
      padding-right: 8px;
    }
  }

  .pemahaman {
    width: 90%;
  }

  .pemahaman-value {
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .input-range__label-container {
    display: none;
  }

  .input-range__track--active {
    background: #51b848;
    height: 8px;
  }

  .input-range__track--background {
    background: #e5f4e4;
    height: 8px;
  }

  .input-range__slider {
    background-color: transparent;
    border-radius: 50%;
    border: 2px solid #51b848;
    height: 1.5rem;
    width: 1.5rem;
    top: -16px;
    right: 50%;
    left: 50%;
    margin-top: 0px;
    display: inline-block;
  }

  .input-range__slider::before {
    background: #51b848;
    border-radius: 50%;
    border: none;
    height: 1rem;
    width: 1rem;
    content: "";
    margin-top: 2px;
    margin-left: 1.7px;
    display: inline-block;
  }

  .cancel-teman {
    position: relative;
    left: 6px;
    cursor: pointer;
    color: #f16ba9;
    font-weight: bold;
  }

  .data_diri_wrapper {
    .taman-input {
      display: flex;
      align-items: center;
      margin-top: 6px;
      margin-bottom: 6px;

      label {
        width: 175px;
        padding-top: 5px;
      }

      input {
        width: calc(100% - 175px);
      }
    }
  }

  .list-jadwal-kelas {
    margin-left: -10px;
    margin-right: -10px;
    padding-bottom: 1rem;
  }

  .list-paket-pembayaran {
    margin-left: -15px;
    margin-right: -15px;
  }

  .select-square-list > * {
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.12);
  }

  .section-materi,
  .list-paket-pertemuan,
  .list-additional-input {
    .select-square-list > * {
      max-width: 300px;
      width: 100%;
      text-align: center;
      font-weight: bold;
    }
  }

  .left-right-div {
    display: flex;
    justify-content: space-between;

    & > *:first-child {
      padding-right: 10px;
    }

    & > *:last-child {
      padding-left: 10px;
    }
  }

  .list-biaya {
    display: flex;
    justify-content: space-between;

    & > div {
      color: #3b3b3b;
    }

    div.bold-title {
      color: black;
      font-weight: bold;
      padding-bottom: 6px;
    }

    .list-biaya-1 {
      width: 50%;
      padding-right: 20px;
    }

    .list-biaya-3 {
      width: 50%;
      padding-left: 20px;
    }

    div.total-harga {
      color: #51b848;
      font-size: 1.75rem;
    }
  }

  @media (max-width: 992px) {
    .list-biaya {
      flex-wrap: wrap;

      .list-biaya-1 {
        width: 100%;
        padding-right: 0;
      }

      .list-biaya-3 {
        width: 100%;
        padding-top: 20px;
        padding-left: 0;
      }
    }
  }

  @media (max-width: 767px) {
    .class-logo-and-desc {
      flex-wrap: wrap;
    }

    .class-logo-and-desc > div:nth-child(1) {
      width: 100%;
      margin-bottom: 20px;
    }

    .class-logo-and-desc > div:nth-child(2) {
      width: 100%;
    }

    .class-logo {
      margin: auto;
    }

    .class-order-form {
      h2 {
        font-size: 1.5rem;
      }
    }
  }

  @media (max-width: 400px) {
    .class-photo-wrapper {
      margin: auto;
      margin-top: 3rem;
      margin-bottom: 2rem;
      max-width: 150px;
    }
  }
`
