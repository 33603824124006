/* eslint-disable camelcase */
import React from "react"
import styled from "styled-components"

const Styles = styled.div`
  padding: 10px;
  color: #3b3b3b;

  .jadwal-component-inside {
    margin: 0;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.12);
    border: 1px solid #e8e8e8;
    border-radius: 8px;
    padding: 12px 15px;
  }
`

export const translateDay = {
  1: "Minggu",
  2: "Senin",
  3: "Selasa",
  4: "Rabu",
  5: "Kamis",
  6: "Jumat",
  7: "Sabtu",
}

export default function JadwalComponent({ list }) {
  return (
    <Styles>
      <div className="jadwal-component-inside">
        {list.map(({ day, hour_start, hour_end }, index) => (
          <div key={index}>
            <b>{translateDay[day]}</b>,{" "}
            {hour_start.slice(0, 5) + " - " + hour_end.slice(0, 5)}
          </div>
        ))}
      </div>
    </Styles>
  )
}
