// Generic imports
import React, { useState, useEffect } from "react"
import { navigate } from "gatsby"

// Other components imports
import Layout from "components/layout"
import OrderClass from "components/OrderClass"
import SEO from "components/seo"

// Utils imports
import { isBrowser } from "services/utils"
import { getOneClass } from "services/kelas"
import {
  trackArrivedAtOrderPage,
  trackErrorAtOrderPage,
} from "trackers/amplitude"

const OrderKelasTemplate = () => {
  const [kelas, setKelas] = useState(null)

  useEffect(() => {
    try {
      if (isBrowser()) {
        const pathList = window.location.pathname.split("/")
        const kelasCode = pathList[2]
        getOneClass(kelasCode)
          .then(resp => {
            if (resp.data) {
              trackArrivedAtOrderPage(resp.data[0])
              setKelas(resp.data[0])
            } else {
              trackErrorAtOrderPage(kelasCode)
              throw new Error()
            }
          })
          .catch(() => {
            navigate("/")
          })
      }
    } catch {
      navigate("/")
    }
  }, [])
  return (
    <Layout>
      <SEO title={kelas ? `Order ${kelas.name}` : "Order Kelas"} />
      {!kelas ? "Loading..." : <OrderClass kelas={kelas} />}
    </Layout>
  )
}

export default OrderKelasTemplate
