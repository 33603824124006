// Generic imports
import React from "react"
import styled from "styled-components"

import JadwalComponent from "components/OrderClass/JadwalComponent"

const Styles = styled.div`
  display: flex;
  flex-wrap: wrap;
  overflow-x: auto;

  & > div {
    max-width: 320px;
    width: 100%;
  }

  & > .active .jadwal-component-inside {
    outline: 2px solid #51b848;
  }
`

export default function InputJadwal({ jadwalGroups, clickedJadwal, value }) {
  return (
    <Styles>
      {jadwalGroups?.map((jadwalGroup, index) => (
        <div
          key={index}
          className={value === jadwalGroup.pk ? "active" : ""}
          onClick={() => clickedJadwal && clickedJadwal(jadwalGroup.pk)}
        >
          <JadwalComponent list={jadwalGroup.jadwals} />
        </div>
      ))}
    </Styles>
  )
}
