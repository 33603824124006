import React, { useState } from "react"
import styled from "styled-components"
import { toast } from "react-toastify"

import Button from "../../Button"

import peopleSvg from "images/order-class/2-person.svg"

import { cekUser } from "services/user"

const Styles = styled.div`
  display: flex;
  width: 100%;
  padding: 6px 8px;
  border: 1px solid #a0a4a8;
  box-sizing: border-box;
  border-radius: 8px;

  .img-undang-teman {
    border-right: 1px solid #a0a4a8;
    box-sizing: border-box;
    border-radius: 8px;
    margin: -8px;
    margin-right: 0;
    padding: 0 12px;
    display: flex;
    align-items: center;
  }

  input {
    width: 100%;
    border: none;
    padding: 0 12px;
  }

  button {
    width: 220px;
    padding: 4px;
    border-radius: 8px;
  }
`

export default function UndangTeman(props) {
  const { id, addTeman, ...otherProps } = props
  const inputId = props.id ? id : props.name
  const [unameEmail, setUnameEmail] = useState("")

  const cobaCekUser = async () => {
    const listUnameEmail = unameEmail.split(",")
    const listTeman = []

    for (let val of listUnameEmail) {
      val = val.trim()
      if (!val) {
        continue
      }
      try {
        const resp = await cekUser(val)
        if (addTeman && resp.data.length > 0) {
          listTeman.push({
            ...resp.data[0],
            uname_email: val,
          })
        } else if (resp.data.length === 0) {
          throw new Error()
        }
      } catch (e) {
        toast.error(`${val} not found. Please inform them to register`)
      }
    }

    addTeman(listTeman)
  }

  return (
    <Styles>
      <div className="img-undang-teman">
        <img src={peopleSvg} alt="Invite" />
      </div>
      <input
        id={inputId}
        onChange={e => {
          setUnameEmail(e.target.value)
        }}
        value={unameEmail}
        {...otherProps}
      />
      <Button type="button" onClick={cobaCekUser}>
        Undang Teman
      </Button>
    </Styles>
  )
}
