// Generic imports
import React, { useState, useEffect } from "react"
import styled from "styled-components"

const Styles = styled.label`
  margin-bottom: 0;

  input {
    /* removing default appearance */
    -webkit-appearance: none;
    appearance: none;
    /* creating a custom design */
    width: 1.4em;
    height: 1.4em;
    border-radius: 0.15em;
    margin-right: 0.5em;
    border: 0.15em solid #51B848;
    outline: none;
    cursor: pointer;
    background-color: white;
  }

  input:checked {
    background-color: #51B848;
    position: relative;
  }

  input:checked::before {
    font-size: 1.2em;
    color: #fff;
    position: absolute;
    right: 1px;
    top: -5px;
    content: "✔";
  }

  input:disabled {
    border-color: #c0c0c0;
    background-color: #c0c0c0;
  }

  input:disabled + span {
    color: #c0c0c0;
  }

  .checkbox-span {
    color: #666666;
    font-size: 14px;
    font-weight: 400;
    position: relative;
    bottom: 5px;
  }
`

export default function Checkbox(props) {
  const { children, id, onChange, value } = props
  const inputId = id !== null ? id : props.name
  const [checkVal, setCheckVal] = useState(0)

  useEffect(() => {
    setCheckVal(value)
  }, [value])

  return (
    <Styles className="taman-checkbox" htmlFor={inputId} onClick={() => {
      setCheckVal(1 - checkVal)
      if (onChange) {
        onChange(1 - checkVal)
      }
    }}>
      <input
        type="checkbox"
        checked={checkVal !== 0}
      />
      <span className="checkbox-span">{children}</span>
    </Styles>
  )
}
